/* frontend/src/components/Controls.css */

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}


.controls button {
  background-color: #958C76;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin: 4px;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
}

.controls button:hover {
  background-color: #B3AA94;
}

.controls .back-button {
  background-color: #2B2A2D;
  color: white;
}



.controls .back-button:hover {
  background-color: #3C3B3E;
 
}

.controls button:disabled {
  background-color: #2B2A2D;
  color: #3C3B3E;
  cursor: not-allowed;
}