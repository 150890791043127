@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.battle-deck {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
/*  border: 1px solid #ccc;
*/  border-radius: 5px;
  background-color: #2B2A2D;
  margin: 10px;
  min-width: calc(120px * 6 + 10px * 6); /* updated width calculation */
}

.battle-deck h3 {
  margin-bottom: 30px;
  color: #5A595C;
}

.cards-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 10px;
}

/* frontend/src/components/BattleDeck.css */

.slot {
  position: relative;
  display: inline-block;
}

.slot-name {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  color: #646366;
  font-size: 14px;
}

/* ... (other existing CSS rules) */
