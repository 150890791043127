.player-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #333235;
  border-radius: 5px;
  background-color: #242326;
  margin: 10px;
  width: 100%;
    opacity: 0.5;
}



.player-area.active {
  opacity: 1;
}

.player-area h2 {
  margin-bottom: 10px;
  color: #646366;
}

.decks-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
}