.card-slot {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  opacity: 1;
}

.battle-card {
  position: relative;
  opacity: 0;
  animation: fadeInMoveUp 0.2s forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 140px;
  border-bottom: 1px solid black;
  border-radius: 12px;
  background-color: #19181b;
  padding: 8px;
  margin: 4px;
  color: #A09FA2;
}

@keyframes fadeInMoveUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-attribute {
  margin-bottom: 4px;
}

.card-class {
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: capitalize;
  color: #958C76;
}

.empty-slot {
  background-color: #222124;
  color: #5A5966;
  width: 120px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #414043;
  border-radius: 12px;
  padding: 8px;
  margin-top: 5px;
}

.card-stack {
  opacity: 0;
  animation: fadeIn 0.2s ease 0s 1 normal forwards;
  animation-delay: var(--delay);
}

.card-count-indicator {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 12px;
}


.card-slot:hover .card-count-indicator {
  visibility: visible;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    bottom: -10px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

.card-count {
 position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #646366;
    border-radius: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    font-size: 0.75em;
    color: white;
}

.card-count span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


.opponent-card .card-attribute {
  visibility: hidden;
}

.opponent-card .battle-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #464646;
  border-radius: 12px;

  opacity: 0.9;
  z-index: 1;

}

