@font-face {
    font-family: Iosevka Etoile Web;
    src: url(./fonts/iosevka-etoile-light.ttf) format("truetype"),url(./fonts/iosevka-etoile-light.woff2) format("woff2");
  }

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Iosevka Etoile Web;
  background-color: #242326;
  color: #333;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
