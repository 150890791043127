/* frontend/src/components/Graveyard.css */



.graveyard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.graveyard h3 {
  margin-bottom: 10px;
    color: #5A595C;
}

.graveyard-card-count {
  width: 90px;
  height: 120px;
  border-radius: 10px;
  background-color: #1A191C;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #3C3B3E;
}
