/* frontend/src/components/ReserveDeck.css */
.reserve-deck {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.reserve-deck h3 {
  margin-bottom: 10px;
  color: #5A595C;
}

.reserve-card-count {
  width: 90px;
  height: 120px;
/*  border-bottom: 4px solid black;
*/  border-radius: 10px;
  background-color: #958C76;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #D1C8B2;
}

.gold-counter {
  margin-top: 10px;
  color: #958C76;
  font-size: 1em;
  font-weight: bold;
}
