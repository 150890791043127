.notification {
  position: fixed;
  top: -50px; /* Change this from "bottom" to "top" */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  animation: dropIn 0.5s forwards;
  opacity: 0;
  z-index: 1000;
}

@keyframes dropIn {
  0% {
    opacity: 0;
    top: -50px;
  }
  100% {
    opacity: 1;
    top: 20px; /* Change this value to control the final position of the notification */
  }
}
